export enum StartTherapySections {
  CHOOSE_CARE_TYPE = "CHOOSE_CARE_TYPE",
  INTRODUCTION = "INTRODUCTION",
  PERSONAL_DATA_IF_MISSING = "PERSONAL_DATA_IF_MISSING",
  YOUR_FOCUS_AREAS = "YOUR_FOCUS_AREAS",
  REFINEMENT_QUESTIONS = "REFINEMENT_QUESTIONS",
  THERAPY_PREFERENCES = "THERAPY_PREFERENCES",
  AWAIT_MATCHES_GENERATED = "AWAIT_MATCHES_GENERATED",
  PROFESSIONAL_COACHING_GOALS = "PROFESSIONAL_COACHING_GOALS",
}

export enum SimplifiedCareTypes {
  THERAPY = "THERAPY",
  COACHING = "COACHING",
  HYBRID_CARE = "HYBRID_CARE",
}

export const StartTherapyOrderedSections: StartTherapySections[] = [
  StartTherapySections.INTRODUCTION,
  StartTherapySections.PERSONAL_DATA_IF_MISSING,
  StartTherapySections.REFINEMENT_QUESTIONS,
  StartTherapySections.THERAPY_PREFERENCES,
  StartTherapySections.AWAIT_MATCHES_GENERATED,
];

/**
 * The arrays below are used when professional coaching is available
 */
export const ReducedProfessionalCoachingOrderedSections: StartTherapySections[] =
  [
    StartTherapySections.PROFESSIONAL_COACHING_GOALS,
    StartTherapySections.AWAIT_MATCHES_GENERATED,
  ];

export const ReducedStartTherapyOrderedSections: StartTherapySections[] = [
  StartTherapySections.PERSONAL_DATA_IF_MISSING,
  StartTherapySections.REFINEMENT_QUESTIONS,
  StartTherapySections.THERAPY_PREFERENCES,
  StartTherapySections.AWAIT_MATCHES_GENERATED,
];

export enum StartTherapyPersonalDataSteps {
  LOCATION,
  DATE_OF_BIRTH,
  PHONE_NUMBER,
  EMERGENCY_CONTACT_INTRODUCTION,
  EMERGENCY_CONTACT_TYPE_CHOICE,
  EMERGENCY_CONTACT_FORM,
}

export const StartTherapyPersonalDataOrderedSteps: StartTherapyPersonalDataSteps[] =
  [
    StartTherapyPersonalDataSteps.LOCATION,
    StartTherapyPersonalDataSteps.DATE_OF_BIRTH,
    StartTherapyPersonalDataSteps.PHONE_NUMBER,
    StartTherapyPersonalDataSteps.EMERGENCY_CONTACT_INTRODUCTION,
    StartTherapyPersonalDataSteps.EMERGENCY_CONTACT_TYPE_CHOICE,
    StartTherapyPersonalDataSteps.EMERGENCY_CONTACT_FORM,
  ];
