import { MentalHealthPillars } from "@prisma/postgresClient";
import MentalHealthTopics from "./MentalHealthTopics";

export default MentalHealthPillars;

export const pillarTopics: {
  pillar: MentalHealthPillars;
  topics: MentalHealthTopics[];
}[] = [
  {
    pillar: MentalHealthPillars.EMOTIONAL,
    topics: [
      MentalHealthTopics.ADJUSTMENT_DISORDER,
      MentalHealthTopics.ANGER,
      MentalHealthTopics.ANXIETY,
      MentalHealthTopics.ATTENTION_AND_HYPERACTIVITY_CONCERNS,
      MentalHealthTopics.BEREAVEMENT_REACTION,
      MentalHealthTopics.BI_POLAR_DEPRESSION,
      MentalHealthTopics.BODY_DYSMORPHIA,
      MentalHealthTopics.BREAKING_HABITS,
      MentalHealthTopics.CULTURAL_IDENTITY,
      MentalHealthTopics.DEPRESSION,
      MentalHealthTopics.FEELING_DEPRESSED_LOW_MOOD,
      MentalHealthTopics.FINDING_PURPOSE,
      MentalHealthTopics.GAMBLING_NON_SUBSTANCE_ADDICTIONS,
      MentalHealthTopics.GENERALIZED_ANXIETY_DISORDER,
      MentalHealthTopics.GRIEF_LOSS,
      MentalHealthTopics.INDECISION,
      MentalHealthTopics.LACK_OF_CONFIDENCE,
      MentalHealthTopics.OBSESSIONS_AND_COMPULSIONS,
      MentalHealthTopics.OCD,
      MentalHealthTopics.PANIC_DISORDER_WITH_AGORAPHOBIA,
      MentalHealthTopics.PANIC_DISORDER_WITHOUT_AGORAPHOBIA,
      MentalHealthTopics.PERFECTIONISM,
      MentalHealthTopics.PHOBIAS,
      MentalHealthTopics.PROCRASTINATION,
      MentalHealthTopics.PTSD,
      MentalHealthTopics.RUMINATION,
      MentalHealthTopics.SELF_ESTEEM,
      MentalHealthTopics.SERIOUS_MENTAL_ILLNESS,
      MentalHealthTopics.SIGNIFICANT_OR_CHRONIC_STRESS,
      MentalHealthTopics.SKIN_PICKING,
      MentalHealthTopics.SUBSTANCE_ALCOHOL_USE_CONCERNS,
      MentalHealthTopics.TRAUMA,
      MentalHealthTopics.TRICHOTILLOMANIA,
      MentalHealthTopics.WORRY_ABOUT_BURNOUT,
      MentalHealthTopics.WORRY_ABOUT_PERFORMANCE_AT_WORK,
      MentalHealthTopics.UNHAPPY_AT_WORK,
      MentalHealthTopics.HELP_WITH_ADOPTING_HEALTHIER_HABITS,
    ],
  },
  {
    pillar: MentalHealthPillars.PROFESSIONAL,
    topics: [
      MentalHealthTopics.BEING_A_MANAGER_FOR_THE_FIRST_TIME,
      MentalHealthTopics.BEING_A_MANAGER_ISSUES,
      MentalHealthTopics.BEING_PROMOTED,
      MentalHealthTopics.BURNOUT,
      MentalHealthTopics.CAREER_CHANGE,
      MentalHealthTopics.COMMUNICATION_ISSUES_AT_WORK,
      MentalHealthTopics.DEALING_WITH_UNCERTAINTY_AT_WORK,
      MentalHealthTopics.DECISION_MAKING,
      MentalHealthTopics.DIVERSITY_EQUITY_INCLUSION_AT_WORK,
      MentalHealthTopics.GIVING_RECEIVING_FEEDBACK,
      MentalHealthTopics.IMPOSTER_SYNDROME,
      MentalHealthTopics.INTERPERSONAL_ISSUES,
      MentalHealthTopics.RECENT_PROMOTION,
      MentalHealthTopics.REMOTE_WORK_ISSUES,
      MentalHealthTopics.STRESS_AND_WORRY_AT_WORK,
      MentalHealthTopics.WORK_LIFE_BALANCE,
      MentalHealthTopics.HELP_WITH_WORK_RELATED_ISSUE,
    ],
  },
  {
    pillar: MentalHealthPillars.PHYSICAL,
    topics: [
      MentalHealthTopics.CHRONIC_PAIN,
      MentalHealthTopics.EATING_WEIGHT_ISSUES,
      MentalHealthTopics.HEALTH_ANXIETY,
      MentalHealthTopics.INSOMNIA,
      MentalHealthTopics.LACK_OF_EXERCISE,
      MentalHealthTopics.LONG_TERM_HEALTH_CONDITION,
      MentalHealthTopics.MEDICALLY_UNEXPLAINED_SYMPTOMS,
      MentalHealthTopics.MINDFULNESS_MEDITATION,
      MentalHealthTopics.QUITTING_SMOKING,
      MentalHealthTopics.SLEEP_ISSUES,
    ],
  },
  {
    pillar: MentalHealthPillars.SOCIAL,
    topics: [
      MentalHealthTopics.ASSERTIVENESS,
      MentalHealthTopics.BEING_A_CARER,
      MentalHealthTopics.BUILDING_NEW_RELATIONSHIPS,
      MentalHealthTopics.BULLYING,
      MentalHealthTopics.DIVERSITY_EQUITY_INCLUSION,
      MentalHealthTopics.IMPROVING_MY_RELATIONSHIPS,
      MentalHealthTopics.MANAGING_CONFLICT,
      MentalHealthTopics.NAVIGATING_CONFLICT,
      MentalHealthTopics.PARENTING_CAREGIVING_FAMILY,
      MentalHealthTopics.SEXUAL_DYSFUNCTION,
      MentalHealthTopics.SOCIAL_ANXIETY,
      MentalHealthTopics.SPEAKING_IN_FRONT_OF_OTHERS,
    ],
  },
  {
    pillar: MentalHealthPillars.FINANCIAL,
    topics: [
      MentalHealthTopics.COMPULSIVE_BUYING,
      MentalHealthTopics.DEALING_WITH_DEBT,
      MentalHealthTopics.DEALING_WITH_FINANCIAL_WORRY,
      MentalHealthTopics.FINANCIAL_ANXIETY,
      MentalHealthTopics.FINANCIAL_STABILITY,
      MentalHealthTopics.FINANCIAL_PRESSURE_FROM_FAMILY_MEMBERS,
    ],
  },
];

export function getPillarForTopic(
  topic: MentalHealthTopics,
): MentalHealthPillars {
  const foundPillar = pillarTopics.find((entry) =>
    entry.topics.find((mappedTopic) => mappedTopic === topic),
  );

  return foundPillar?.pillar ?? MentalHealthPillars.EMOTIONAL;
}
