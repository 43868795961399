import { MentalHealthTopics } from "@prisma/postgresClient";

export default MentalHealthTopics;

/**
 * List of topics that are treated exclusively by therapists
 */
export const TherapyExclusiveTopics: MentalHealthTopics[] = [
  // emotional pillar
  MentalHealthTopics.ADJUSTMENT_DISORDER,
  MentalHealthTopics.BEREAVEMENT_REACTION,
  MentalHealthTopics.BI_POLAR_DEPRESSION,
  MentalHealthTopics.BODY_DYSMORPHIA,
  MentalHealthTopics.DEPRESSION,
  MentalHealthTopics.FEELING_DEPRESSED_LOW_MOOD,
  MentalHealthTopics.GAMBLING_NON_SUBSTANCE_ADDICTIONS,
  MentalHealthTopics.GENERALIZED_ANXIETY_DISORDER,
  MentalHealthTopics.GRIEF_LOSS,
  MentalHealthTopics.OBSESSIONS_AND_COMPULSIONS,
  MentalHealthTopics.OCD,
  MentalHealthTopics.PANIC_DISORDER_WITH_AGORAPHOBIA,
  MentalHealthTopics.PANIC_DISORDER_WITHOUT_AGORAPHOBIA,
  MentalHealthTopics.PHOBIAS,
  MentalHealthTopics.PTSD,
  MentalHealthTopics.RUMINATION,
  MentalHealthTopics.SERIOUS_MENTAL_ILLNESS,
  MentalHealthTopics.SIGNIFICANT_OR_CHRONIC_STRESS,
  MentalHealthTopics.SKIN_PICKING,
  MentalHealthTopics.SUBSTANCE_ALCOHOL_USE_CONCERNS,
  MentalHealthTopics.TRAUMA,
  MentalHealthTopics.TRICHOTILLOMANIA,
  // physical pillar
  MentalHealthTopics.CHRONIC_PAIN,
  MentalHealthTopics.EATING_WEIGHT_ISSUES,
  MentalHealthTopics.HEALTH_ANXIETY,
  MentalHealthTopics.INSOMNIA,
  MentalHealthTopics.MEDICALLY_UNEXPLAINED_SYMPTOMS,
  MentalHealthTopics.LONG_TERM_HEALTH_CONDITION,
  MentalHealthTopics.SLEEP_ISSUES,
  // social pillar
  MentalHealthTopics.BEING_A_CARER,
  MentalHealthTopics.SEXUAL_DYSFUNCTION,
  MentalHealthTopics.SOCIAL_ANXIETY,
  // financial pillar
  MentalHealthTopics.COMPULSIVE_BUYING,
  MentalHealthTopics.FINANCIAL_PRESSURE_FROM_FAMILY_MEMBERS,
];

/**
 * List of topics that can be treated by professional coaches
 */
export const ProfessionalCoachingTopics: MentalHealthTopics[] = [
  // emotional pillar
  MentalHealthTopics.ANGER,
  MentalHealthTopics.ANXIETY,
  MentalHealthTopics.ATTENTION_AND_HYPERACTIVITY_CONCERNS,
  MentalHealthTopics.BREAKING_HABITS,
  MentalHealthTopics.CULTURAL_IDENTITY,
  MentalHealthTopics.FINDING_PURPOSE,
  MentalHealthTopics.INDECISION,
  MentalHealthTopics.LACK_OF_CONFIDENCE,
  MentalHealthTopics.PERFECTIONISM,
  MentalHealthTopics.PROCRASTINATION,
  MentalHealthTopics.SELF_ESTEEM,
  MentalHealthTopics.WORRY_ABOUT_BURNOUT,
  MentalHealthTopics.WORRY_ABOUT_PERFORMANCE_AT_WORK,
  MentalHealthTopics.UNHAPPY_AT_WORK,
  MentalHealthTopics.HELP_WITH_ADOPTING_HEALTHIER_HABITS,
  // professional pillar
  MentalHealthTopics.BEING_A_MANAGER_FOR_THE_FIRST_TIME,
  MentalHealthTopics.BEING_A_MANAGER_ISSUES,
  MentalHealthTopics.BEING_PROMOTED,
  MentalHealthTopics.BURNOUT,
  MentalHealthTopics.CAREER_CHANGE,
  MentalHealthTopics.COMMUNICATION_ISSUES_AT_WORK,
  MentalHealthTopics.DEALING_WITH_UNCERTAINTY_AT_WORK,
  MentalHealthTopics.DECISION_MAKING,
  MentalHealthTopics.DIVERSITY_EQUITY_INCLUSION_AT_WORK,
  MentalHealthTopics.GIVING_RECEIVING_FEEDBACK,
  MentalHealthTopics.IMPOSTER_SYNDROME,
  MentalHealthTopics.INTERPERSONAL_ISSUES,
  MentalHealthTopics.RECENT_PROMOTION,
  MentalHealthTopics.REMOTE_WORK_ISSUES,
  MentalHealthTopics.STRESS_AND_WORRY_AT_WORK,
  MentalHealthTopics.WORK_LIFE_BALANCE,
  MentalHealthTopics.HELP_WITH_WORK_RELATED_ISSUE,
  // physical pillar
  MentalHealthTopics.LACK_OF_EXERCISE,
  MentalHealthTopics.MINDFULNESS_MEDITATION,
  // social pillar
  MentalHealthTopics.ASSERTIVENESS,
  MentalHealthTopics.BUILDING_NEW_RELATIONSHIPS,
  MentalHealthTopics.BULLYING,
  MentalHealthTopics.DIVERSITY_EQUITY_INCLUSION,
  MentalHealthTopics.IMPROVING_MY_RELATIONSHIPS,
  MentalHealthTopics.MANAGING_CONFLICT,
  MentalHealthTopics.NAVIGATING_CONFLICT,
  MentalHealthTopics.PARENTING_CAREGIVING_FAMILY,
  MentalHealthTopics.SPEAKING_IN_FRONT_OF_OTHERS,
  // financial pillar
  MentalHealthTopics.DEALING_WITH_DEBT,
  MentalHealthTopics.DEALING_WITH_FINANCIAL_WORRY,
  MentalHealthTopics.FINANCIAL_ANXIETY,
  MentalHealthTopics.FINANCIAL_STABILITY,
];
